import BlogLayout from '../components/containers/BlogLayout'
import Head from '../components/informational/Head'
import { Link } from 'gatsby'
import MaterialLink from '@material-ui/core/Link'
import React from 'react'
import Typography from '@material-ui/core/Typography'

const Blog = ({ classes }) => (
  <>
    <Head title='How to download source code of any website?' description='In order to download a website all you need is the website address (URL, for eg. apple.com or google.com), an internet connection and WebsiteDownloader.io' />

    <BlogLayout>
      <Typography variant='h1' gutterBottom>
        How to download source code of any website?
      </Typography>
      <Typography variant='h4' color='textSecondary' gutterBottom>
        Updated On: February 10, 2019 | Written By: Debjit Saha
      </Typography>
      <Typography variant='body1' gutterBottom>
        In order to download a website all you need is the website address (URL, for eg. apple.com or google.com), an internet connection and WebsiteDownloader.io
      </Typography>
      <Typography variant='body1' gutterBottom>
        WebsiteDownloader.io is a great tool that lets you <MaterialLink component={Link} to='/'>download the source code of any website</MaterialLink> which includes the HTML files, static assets like JS (Javascript), CSS, Images and PDF documents.
      </Typography>
      <Typography variant='body1' gutterBottom>
        All you need to do is enter the Website URL you want to download into <MaterialLink component={Link} to='/'>WebsiteDownloader.io</MaterialLink> and after a couple of minutes, depending on the size of the website, you will get a zip that would contain the HTML source code of the website.
      </Typography>
      <Typography variant='body1' gutterBottom>
        You can also preview the list of files that the zip would include so that you are fully assured of the contents of the ZIP.
      </Typography>
      <Typography variant='body1' gutterBottom>
          The world now has over a one billion websites and the entire world population is 7.4 billion as of writing this article. This means, there is 1 website for every 7 people on the Earth. Although, it does not work exactly like that but it would not be wrong to make an estimation like this – 1 website for every 7 people!
      </Typography>
    </BlogLayout>
  </>
)

export default Blog
